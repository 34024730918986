<template>
  <div class="iconList" v-if="listHotClass">
    <div
      class="iconItem"
      v-for="(item, index) in listHotClass"
      :key="index"
      @click="getSearch(item.HotClassId, item.ClassName)"
    >
      <van-image class="iconImg" :src="imgUrlFilter(item.Icon)">
        <template v-slot:error>
          <img class="ImgIcon" :src="iconList[index].imgUrl" />
        </template>
      </van-image>

      <div class="iconTitle">{{ item.ClassName }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "IconList",
  components: {},
  props: {
    listHotClass: {
      type: Array,
    },
  },
  data() {
    return {
      iconList: [
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_gift.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_house.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_clock.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_phone.png"),
          name: "vacheeen",
        },
        {
          imgUrl: require("@/assets/img/chome/new/icon_home_bag.png"),
          name: "vacheeen",
        },
      ],
    };
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    // 跳转详情页
    getSearch(ClassId, ClassName) {
      if (ClassId.toString() === "-999") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "allProducts",
          name: "AllProducts",
          query: [],
        });
        return false;
      }
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "ClassName",
            value: ClassName,
          },
          {
            name: "ClassId",
            value: ClassId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
// 是设计图是三杯图
.iconList {
  width: 95%;
  text-align: center;
  margin: auto;
  .iconItem {
    vertical-align: top;
    display: inline-block;
    width: 20%;
    .iconImg {
      height: 12vw;
      width: 12vw;
      .ImgIcon {
        width: 100%;
      }
      ::v-deep .van-image__img {
        width: 100%;
      }
      ::v-deep .van-image__error {
        width: 100%;
      }
    }
    .iconTitle {
      font-size: 12px;
      font-family: Helvetica;
      font-weight: 400;
      color: #000000;
      width: 90%;
      text-align: center;
      margin: auto;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: normal;
    }
  }
}
</style>
